import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { ClientTemplate, AdminTemplate } from "template";
import { clientRoutes, adminRoutes } from "app/config/routes";
import SignUp from "app/modules/auth/signUp";
import SignIn from "app/modules/auth/signIn";
import ClientModulesHome from "app/modules/clientModules/clientModulesHome";

function App() {

  return (
    <Router>
      <Routes>
        <Route path="/signup" element={<SignUp />} />
        <Route path="/signin" element={<SignIn />} />
        <Route path="/" element={<ClientModulesHome />} />
        <Route element={<ClientTemplate />}>
          {clientRoutes.map((route, index) => {
            return (
              <Route
                path={route.path}
                element={<route.component />}
                key={index}
              />
            );
          })}
        </Route>
        <Route element={<AdminTemplate />}>
          {adminRoutes.map((route, index) => {
            return (
              <Route
                path={route.path}
                element={<route.component />}
                key={index}
              />
            );
          })}
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
