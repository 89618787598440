import React, { useState } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../../config/firebase';
import { getFirestore, collection, query, where, getDocs } from 'firebase/firestore';
import './authstyles.scss';
import backgroundImg from "assets/images/backgroundSignIn.png";

const SignIn = () => {
    const [phone, setPhone] = useState('');
    const [password, setPassword] = useState('');
    const db = getFirestore();
    const navigate = useNavigate();
    const location = useLocation();
    const from = location.state?.from?.pathname || '/';

    const handleSignIn = async (e) => {
        e.preventDefault();
        try {
            const q = query(collection(db, 'users'), where('phone', '==', phone));
            const querySnapshot = await getDocs(q);
            if (querySnapshot.empty) {
                throw new Error('Không tìm thấy tài khoản với số điện thoại này');
            }
            const userDoc = querySnapshot.docs[0];
            const email = userDoc.data().email;

            await signInWithEmailAndPassword(auth, email, password);
            alert('Đăng Nhập Thành Công, Bấm OK để tiếp tục');
            navigate(from, { replace: true });
        } catch (error) {
            alert('Sai Mật Khẩu , Xin Vui Lòng Thử Lại');
        }
    };

    const handleGoBack = () => {
        navigate(-1); // Navigate back to the previous screen
    };

    return (
        <div className="auth-container" style={{
            backgroundImage: `url(${backgroundImg})`,
            backgroundSize: 'cover', // Ensures the image covers the div
            backgroundPosition: 'center', // Centers the image
        }}>
            <form className="auth-form" onSubmit={handleSignIn}>
                <button onClick={handleGoBack} className="back-button">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    >
                        <polyline points="15 18 9 12 15 6" />
                    </svg>
                </button>
                <h2>Đăng Nhập</h2>
                <input
                    type="tel"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                    placeholder="Số Điện Thoại"
                    className="auth-input"
                />
                <input
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="Mật Khẩu"
                    className="auth-input"
                />
                <button type="submit" className="auth-button">Đăng Nhập</button>
                <p className="auth-text">
                    Chưa có tài khoản?
                </p>
                <Link to="/signup" className="auth-link">Tạo tài khoản mới</Link>
            </form>
        </div>
    );
};

export default SignIn;