import axios from "axios";
import { isEmpty } from "lodash";
import {
  FETCH_CART,
  ADD_PRODUCT,
  FETCH_LOCAL_BILL,
  REMOVE_COURSE,
  ADD_COURSE_TO_CART,
  REMOVE_COURSE_FROM_CART,
  CHANGE_COUNT_OF_COURSE,
  ADD_COURSE_ONLINE_TO_CART,
  REMOVE_COURSE_ONLINE_FROM_CART,
  CHECKOUT_INFO,
} from "../constant/cartConstants";

import { showToast } from "core/utils/toastUtil";

import { cloneDeep } from "lodash";

export const cartAction = () => {
  return async (dispatch) => {
    dispatch({
      type: FETCH_CART,
    });
  };
};

export const addProduct = (dataSubmit) => {
  return async (dispatch) => {
    dispatch({
      type: ADD_PRODUCT,
      payload: dataSubmit,
    });
  };
};
export const addCourseToCart = (dataSubmit) => {
  return async (dispatch) => {
    dispatch({
      type: ADD_COURSE_TO_CART,
      payload: dataSubmit,
    });
  };
};
export const removeCourseFromCart = (dataSubmit, sendData) => {
  return async (dispatch, getState) => {
    const { billCheck } = getState().cart; // Assuming cart is your slice of state
    let billList = [...billCheck];

    if (dataSubmit.type === "courseOffline") {
      let index = billList.findIndex(
        (obj) =>
          obj.course === dataSubmit.course && obj.ticket === dataSubmit.ticket
      );

      if (index !== -1) {
        billList.splice(index, 1);
        // Perform the API call if sendData is true
        if (sendData) {
          try {
            let finalData = cloneDeep(dataSubmit.finalData);
            finalData["tags"] = ["payment-success"];
            finalData["import_by_tag_name"] = true;
            finalData["giave"] = dataSubmit.price;
            console.log("finalData", finalData);
            await axios({
              method: "POST",
              url: "https://api.service.ladiflow.com/1.0/customer/create-or-update",
              headers: {
                "Api-Key": "a21928473afdf60440c8adddec916036aac285ce560b0133",
              },
              data: finalData,
            });
          } catch (error) {
            console.error("API call failed:", error);
          }
        }
        // Update localStorage and dispatch the action to update state
        localStorage.setItem(
          "billCheckout",
          billList.length ? JSON.stringify(billList) : "[]"
        );

        dispatch({
          type: REMOVE_COURSE_FROM_CART,
          payload: billList,
        });
      }
    }
  };
};
export const changeCountOfCourse = (dataSubmit, type) => {
  return async (dispatch) => {
    dispatch({
      type: CHANGE_COUNT_OF_COURSE,
      payload: { item: dataSubmit, type },
    });
  };
};

export const addCourseOnlineToCart = (dataSubmit) => {
  return async (dispatch, getState) => {
    const { billCheck } = getState().cart;
    let billList = [...billCheck];
    if (isEmpty(billList)) {
      billList.push(dataSubmit);
      showToast("success", "Đã thêm vào giỏ hàng", {
        timeout: 5000,
      });
    } else {
      if (dataSubmit.pharse === "Trọn bộ") {
        // Filter out elements that have matching `schedule_id`
        billList = billList?.filter(
          (item) =>
            item.schedule_id !== dataSubmit.schedule_id ||
            item.pharse === "Trọn bộ"
        );
        const index = billList?.findIndex(
          (y) =>
            y.schedule_id === dataSubmit.schedule_id &&
            y.pharse === dataSubmit.pharse
        );
        if (index === -1) {
          billList.push(dataSubmit);
          showToast("success", "Đã thêm Trọn Bộ khóa học vào giỏ hàng", {
            timeout: 5000,
          });
        } else {
          showToast("error", "Đã tồn tại trong giỏ hàng", {
            timeout: 5000,
          });
        }
      } else {
        let checkAll = billList?.findIndex(
          (y) =>
            y.schedule_id === dataSubmit.schedule_id && y.pharse === "Trọn bộ"
        );
        if (checkAll === -1) {
          const index = billList?.findIndex(
            (y) =>
              y.schedule_id === dataSubmit.schedule_id &&
              y.pharse === dataSubmit.pharse
          );
          if (index === -1) {
            billList.push(dataSubmit);

            showToast("success", "Đã thêm vào giỏ hàng", {
              timeout: 5000,
            });
          } else {
            showToast("error", "Đã tồn tại trong giỏ hàng", {
              timeout: 5000,
            });
          }
        } else {
          showToast("error", "Đã đăng ký trọn bộ khóa học trong giỏ hàng", {
            timeout: 5000,
          });
        }
      }
    }
    const prodLocal = JSON.stringify(billList);
    localStorage.setItem("billCheckout", prodLocal);
    dispatch({
      type: ADD_COURSE_ONLINE_TO_CART,
      payload: billList,
    });
  };
};
export const removeCourseOnlineFromCart = (dataSubmit, sendData) => {
  return async (dispatch, getState) => {
    const { billCheck } = getState().cart; // Assuming cart is your slice of state
    let billList = [...billCheck];
    if (dataSubmit.type === "courseOnline") {
      // let dataSubmit = action.payload;
      let schedule_id = dataSubmit.schedule_id;
      let pharse = dataSubmit.pharse;
      let index = billList.findIndex(
        (obj) => obj.schedule_id === schedule_id && obj.pharse === pharse
      );
      console.log("index", index);
      if (index !== -1) {
        billList.splice(index, 1);
        showToast("success", "Xóa Khóa Học Online Thành Công", {
          timeout: 5000,
        });
      }
      localStorage.setItem(
        "billCheckout",
        billList.length ? JSON.stringify(billList) : "[]"
      );

      dispatch({
        type: REMOVE_COURSE_ONLINE_FROM_CART,
        payload: billList,
      });
    }
  };
};
export const fetchLocalBill = (dataSubmit) => {
  return async (dispatch) => {
    dispatch({
      type: FETCH_LOCAL_BILL,
      payload: dataSubmit,
    });
  };
};

export const removeProductAction = (id) => {
  return async (dispatch) => {
    dispatch({
      type: REMOVE_COURSE,
      payload: id,
    });
  };
};

export const checkoutInfo = (product) => {
  return async (dispatch) => {
    dispatch({
      type: CHECKOUT_INFO,
      payload: product,
    });
  };
};
export const CreatePaymentUrl = (vnp_BankCode) => {
  return async (dispatch) => {};
};
