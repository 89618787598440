import Per01 from "assets/images/per01.png";
import Per02 from "assets/images/per02.png";
import Per03 from "assets/images/per03.png";
import Per04 from "assets/images/per04.png";
// import Per05 from "assets/images/per05.png";
import voucher500 from "assets/images/home/voucher500.jpg";
import serCoach from "assets/images/home/ser-coach-11.jpg";
import serWedding from "assets/images/home/ser-wedding.jpg";
import serBbl from "assets/images/home/ser-bbl.jpg";
import serWorkshop from "assets/images/home/ser-workshop.jpg";
import mediaInfor01 from "assets/images/mediaInfor01.png";
import mediaInfor02 from "assets/images/mediaInfor02.png";
import mediaInfor03 from "assets/images/mediaInfor03.png";
import mediaInfor04 from "assets/images/mediaInfor04.png";
import chamvachua01 from "assets/images/chamvachua1.png";
import chamvachua02 from "assets/images/chamvachua2.png";
import chamvachua03 from "assets/images/chamvachua3.png";
import chamvachua04 from "assets/images/chamvachua4.png";
// catelory

import flower1 from "assets/images/home/flower1.jpg";
import flower2 from "assets/images/home/flower2.jpg";
import flower3 from "assets/images/home/flower3.jpg";
import flower4 from "assets/images/home/flower4.jpg";

// coach
import coach01 from "assets/images/home/coach01.jpg";
import coach02 from "assets/images/home/coach02.jpg";
import coach03 from "assets/images/home/coach03.jpg";
import coach05 from "assets/images/home/coach05.jpg";
import coach06 from "assets/images/home/coach06.jpg";
import coach07 from "assets/images/home/coach07.jpg";
import coach08 from "assets/images/home/coach08.jpg";
import avtThanh from "assets/images/home/avtThanh.jpg";
import podcast from "assets/images/home/podcast.png";

import anh0 from "assets/images/anh0.jpg";
import anh1 from "assets/images/anh1.jpg";
import anh2 from "assets/images/anh2.jpg";
import anh3 from "assets/images/anh3.jpg";
import anh4 from "assets/images/anh4.jpg";
import anh5 from "assets/images/anh5.jpg";
import anh6 from "assets/images/anh6.jpg";
import anh7 from "assets/images/anh7.jpg";
import anh8 from "assets/images/anh8.jpg";
import anh9 from "assets/images/anh9.jpg";
export const mediaInfor = {
  title: "Một số thông tin về Thanh",
  avt: avtThanh,
  podcast: {
    title: "Podcast Chạm chữa",
    img: podcast,
    des: "Một sản phẩm tinh thần đặt tâm huyết bởi Thanh sẽ mang đến cho khán giả những cung bậc cảm xúc qua câu chuyện của các nhân vật khách mời. Qua đó truyền tải thông điệp tuyệt vời đến với khán giả",
    callToAction: "Xem Ngay",
    url: "https://www.youtube.com/watch?v=sAxQVIs7eNc&list=PLLdi12zP_y4BHGCOw2_734fiIhP7zrEgB",
  },
  new: {
    title: "TIN TỨC",
    vnExpress: [
      "https://vnexpress.net/om-cay-thoi-mien-de-chua-lanh-4734217.html",
      "https://vnexpress.net/luc-duc-vi-chuyen-chon-an-tet-nha-noi-nha-ngoai-4706110.html",
    ],
    youth: [
      "https://thanhnien.vn/nam-gioi-di-bar-va-nhung-chuyen-kho-do-185230726205525284.htm",
      "https://thanhnien.vn/nguoi-tre-lam-gi-ngoai-duong-luc-1-gio-sang-185230717215657972.htm",
    ],
    eva: [
      "https://eva.vn/nuoi-con/chuyen-gia-tam-ly-la-ha-giang-thanh-trai-nghiem-tram-cam-khi-mang-thai-c13a605507.html",
    ],
    nuDoanhNhan: [
      "https://nudoanhnhan.net/nlp-master-trainer-la-ha-giang-thanh-tim-lai-niem-vui-sau-nhung-chan-thuong-tam-ly.html",
    ],
    callToAction: "Xem Ngay",
  },
};
export const value = [
  "Khai phá những nét đẹp, điểm mạnh và sự độc đáo bên trong bạn.",
  "Học cách khắc phục điểm yếu của bản thân",
  "Học phương pháp Somatic Healing (chữa lành cảm xúc và tâm hồn)",
  "Lập kế hoạch xây dựng hình tượng thu hút của riêng bạn",
  "Khám phá phương pháp chống lão hóa tự nhiên, làm sạch độc tố trong cơ thể",
  "Kích hoạt năng lực tự chữa lành cho tâm trí và cơ thể",
  "Bí Quyết Chăm Sóc Cơ Thể - Cách Dưỡng Da, Tóc, Và Cả Tâm Hồn",
  "Hàn gắn tổn thương tâm lý nội tâm với chính mình và những người thân yêu xung quanh",
];

export const arrayTes = [
  {
    name: "Trần Thị Tâm",
    img: Per01,
    text: "Cô Thanh đã giúp tôi học được cách lắng nghe và thấu hiểu gia đình hơn",
  },
  {
    name: "Nguyễn Anh Dũng",
    img: Per02,
    text: "Cô Thanh giúp tôi đặt ra mục tiêu, và từng bước xây dựng cuộc sống như mình mong muốn",
  },
  {
    name: "Nguyễn Thị Hồng Hạnh",
    img: Per03,
    text: "Tôi biết cách giao tiếp hiệu quả hơn, mối quan hệ trong cuộc sống được cải thiện",
  },
  {
    name: "Hà Thị Thu Hiền",
    img: Per04,
    text: "Tôi biết cách quản lý cảm xúc tốt hơn, giảm bớt căng thẳng và lo âu trong cuộc sống",
  },
];

export const arrayService = [
  {
    img: serCoach,
    title: "TRỊ LIỆU TÂM LÝ CHUYÊN SÂU 1:1",
    arrayContent: [
      "Định hướng kế hoạch tương lai",
      "Nhận ra gốc rễ vấn đề và đưa ra giải pháp",
      "Trải nghiệm quy trình chữa lành chuyên sâu",
      "Cải thiện các mối quan hệ",
      "Lấy lại năng lượng tích cực",
      "Phát triển trở thành phiên bản tốt nhất của bản thân",
    ],
    type: "coaching",
  },
  {
    img: serWedding,
    title: "KHÓA HỌC TRỊ LIỆU TÂM LÝ ĐẸP & ĐƯỢC YÊU",
    priceDefault: "30.000.000",
    pricePromo: "20.000.000",
    arrayContent: [],
    callToAction: "",
    type: "lrbbl",
  },
  {
    img: serBbl,
    title: "BẠN XỨNG ĐÁNG ĐƯỢC YÊU THƯƠNG",
    arrayContent: [
      "Tham gia khóa học miễn phí",
      "Được gặp gỡ chuyên gia",
      "Đặt câu hỏi cho chuyên gia",
      "Học những bài học hay giá trị",
    ],
    promo: voucher500,
    type: "lrlivestream",
  },
  {
    img: serWorkshop,
    title: "WORKSHOP BLOOM AND BALANCE",
    type: "lrworkshop",
  },
];
export const arrayTrainerCoach = [
  {
    src: coach01,
    caption: "TÌNH YÊU, HÔN NHÂN GIA ĐÌNH",
    tags: [
      { value: "Ocean", title: "Ocean" },
      { value: "People", title: "People" },
    ],
  },
  { src: coach02, caption: "SỐC SAU SANG CHẤN" },
  {
    src: coach03,
    caption: "TRẦM CẢM, LO ÂU, RỐI LOẠN TÂM LÝ",
  },
  {
    src: coach02,
    caption: "TÂM LÝ CHO NGƯỜI ĐỒNG TÍNH, CHUYỂN GIỚI VÀ THÂN NHÂN",
  },

  {
    src: coach05,
    caption: "TÂM LÝ TRẺ EM, NUÔI DẠY CON CÁI",
  },
  {
    src: coach06,
    caption: "PHÁT TRIỂN KỸ NĂNG NGHỀ NGHIỆP, MÂU THUẪN CÔNG SỞ",
  },
  {
    src: coach07,
    caption: "HUẤN LUYỆN KỸ NĂNG DÀNH CHO HỌC SINH - THANH THIẾU NIÊN",
  },
  {
    src: coach08,
    caption: "CÁC VẤN ĐỀ TÂM LÝ CUỘC SỐNG KHÁC",
  },
];
export const valueTitle = "giá trị chương trình";
export const arrayTrainerInfo = [
  "Chứng nhận Master Trainer (được cấp bởi NLP UNIVERSITY & ABNLP)",
  "Chuyên gia trị liệu dòng thời gian (Được phê duyệt bởi HIỆP HỘI TRỊ LIỆU DÒNG THỜI GIAN)",
  "Nhà trị liệu thôi miên - Được phê duyệt bởi HỘI ĐỒNG TRỊ LIỆU THÔI MIÊN HOA KỲ",
  "Chuyên gia khai phá tiềm năng vô thức (được chứng nhận bởi IAGC)",
  "Chuyên gia đào tạo và tư vấn doanh nghiệp",
  "Đào tạo trực tiếp hàng nghìn khách hàng từ trẻ em đến người lớn, các bạn sinh viên, công nhân viên chức, người lao động, đến chủ doanh nghiệp trên khắp Việt Nam nói riêng & khu vực Đông Nam Á nói chung.",
  "Chuyên gia quốc tế về tư vấn trị liệu cá nhân (COACHING 1-1) chuyên sâu về các vấn đề cho các khách hàng cá nhân và chủ doanh nghiệp.",
  "14 năm kinh nghiệm trong lĩnh vực phát triển bản thân đặc biệt là Lập Trình Ngôn Ngữ Tư Duy (NLP) - người tiên phong trong việc xây dựng định hình thị trường cho NLP & Khoa học Tâm Trí để trở thành một dịch vụ có giá trị cao.",
];
export const arrayMediaImg = [
  {
    img: mediaInfor01,
    link: "https://thanhnien.vn/nam-gioi-di-bar-va-nhung-chuyen-kho-do-185230726205525284.htm",
  },
  {
    img: mediaInfor02,
    link: "https://thanhnien.vn/nguoi-tre-lam-gi-ngoai-duong-luc-1-gio-sang-185230717215657972.htm",
  },
  {
    img: mediaInfor03,
    link: "https://vnexpress.net/luc-duc-vi-chuyen-chon-an-tet-nha-noi-nha-ngoai-4706110.html",
  },
  {
    img: mediaInfor04,
    link: "https://vnexpress.net/om-cay-thoi-mien-de-chua-lanh-4734217.html",
  },
  {
    img: chamvachua01,
    link: "https://youtu.be/O7CktVtyL5s?si=r5L6IP0Kl29va_yC",
  },
  {
    img: chamvachua02,
    link: "https://youtu.be/gyDqo2WM7ww?si=2AYIwOuzdUwst74Y",
  },
  {
    img: chamvachua03,
    link: "https://youtu.be/UX99DSdrwHw?si=huRXKiy-BWu3How5",
  },
  {
    img: chamvachua04,
    link: "https://youtu.be/genC5RO8XFQ?si=_TXd2sHSlqfUSsUv",
  },
];

export const catelogyImg = [
  {
    img: flower1,
    // title: "sr_adventure_club",
    // link: "",
  },
  {
    img: flower2,
    // title: "corporation",
    // link: "/hop-tac/huan-luyen-doanh-nghiep",
  },
  {
    img: flower3,
    // title: "performance",
    // link: "/hop-tac/toi-uu-thanh-tich",
  },
  {
    img: flower4,
    // title: "coach",
    // link: "/hop-tac/tu-van-tri-lieu-ca-nhan",
  },
];
export const peoplePopup = [
  {
    name: "Nguyễn Hoàng Anh",
    phone: "034xxxxxxx",
    pic: anh0,
  },
  {
    name: "Lê Minh Khang",
    phone: "098xxxxxxx",
    pic: anh1,
  },
  {
    name: "Trần Khánh Linh",
    phone: "0938xxxxxx",
    pic: anh2,
  },
  {
    name: "Phạm Gia Huy",
    phone: "097xxxxxxx",
    pic: anh3,
  },
  {
    name: "Bùi Thiên An",
    phone: "098xxxxxxx",
    pic: anh4,
  },
  {
    name: "Đỗ Hải Yến",
    phone: "0378xxxxxx",
    pic: anh5,
  },
  {
    name: "Vũ Tuấn Kiệt",
    phone: "039xxxxxxx",
    pic: anh6,
  },
  {
    name: "Phan Ngọc Bích",
    phone: "098xxxxxxx",
    pic: anh7,
  },

  {
    name: "Võ Anh Dũng",
    phone: "0937xxxxxx",
    pic: anh8,
  },

  {
    name: "Dương Thảo Vy",
    phone: "098xxxxxxx",
    pic: anh9,
  },
]

export const onlineCourse = [
  {
    id: 0,
    title: "ZOOM",
    description: "Thông tin bài 1",
    videoTitle: "Bài giảng 1",
    price: "300.000",
  },
  {
    id: 1,
    title: "Làm sạch tâm trí rối loạn",
    description: "Thông tin bài 1",
    videoURL: "https://player.vimeo.com/video/1021943942",
    videoTitle: "Bài giảng 1",
    price: "50.000",
  },
  {
    id: 2,
    title: "Kết nối vô thức",
    description: "Thông tin bài 2",
    videoURL: "https://player.vimeo.com/video/1021945381",
    videoTitle: "Bài giảng 2",
    price: "50.000",
  },
  {
    id: 3,
    title: "Suối nguồn tươi trẻ những hiểu lầm về sự thật",
    description: "Thông tin bài 3",
    videoURL: "https://player.vimeo.com/video/1021944582",
    videoTitle: "Bài giảng 3",
    price: "50.000",
  },
  {
    id: 4,
    title: "Sắc đẹp hoàn hảo Mind - Body - Spirit",
    description: "Thông tin bài 4",
    videoURL: "https://player.vimeo.com/video/1021945955",
    videoTitle: "Bài giảng 4",
    price: "50.000",
  },
  {
    id: 5,
    title: "Kỹ Năng Cân Bằng Trạng Thái",
    description: "Thông tin bài 5",
    videoURL: "https://player.vimeo.com/video/1021986878",
    videoTitle: "Bài giảng 5",
    price: "50.000",
  },
  {
    id: 6,
    title: "Kỹ Năng Tận Hưởng Cuộc Sống",
    description: "Thông tin bài 6",
    videoURL: "https://player.vimeo.com/video/1021987576",
    videoTitle: "Bài giảng 6",
    price: "50.000",
  },
]