// firebaseConfig.js
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyDhQOnU4d8VqTqd75VPw2_5jc-8hQFPjfs",
    authDomain: "lrtherapycenter.firebaseapp.com",
    projectId: "lrtherapycenter",
    storageBucket: "lrtherapycenter.appspot.com",
    messagingSenderId: "241783411813",
    appId: "1:241783411813:web:c272434a16e6412b6d628c"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);

export { auth, db };