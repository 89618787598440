import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../../config/firebase';
import { getFirestore, doc, setDoc } from 'firebase/firestore';
import './authstyles.scss';
import backgroundImg from "assets/images/backgroundSignIn.png";

const SignUp = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [phone, setPhone] = useState('');
    const [nickname, setNickname] = useState('');
    const [isEmailValid, setIsEmailValid] = useState(true);
    const [isPhoneValid, setIsPhoneValid] = useState(true);
    const db = getFirestore();
    const navigate = useNavigate();

    const validateEmail = (email) => {
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return re.test(String(email).toLowerCase());
    };

    const validatePhone = (phone) => {
        const re = /^\d{10}$/;
        return re.test(String(phone));
    };

    const handleSignUp = async (e) => {
        e.preventDefault();
        if (!validateEmail(email)) {
            setIsEmailValid(false);
            return;
        }
        if (!validatePhone(phone)) {
            setIsPhoneValid(false);
            return;
        }
        try {
            const userCredential = await createUserWithEmailAndPassword(auth, email, password);
            const user = userCredential.user;
            await setDoc(doc(db, 'users', user.uid), {
                email,
                phone,
                nickname,
                boughtOnline: false,
            });
            alert('Đăng Ký Thành Công');
            navigate('/signin');
        } catch (error) {
            alert(error.message);
        }
    };

    const handleGoBack = () => {
        navigate(-1); // Navigate back to the previous screen
    };

    return (
        <div className="auth-container" style={{
            backgroundImage: `url(${backgroundImg})`,
            backgroundSize: 'cover', // Ensures the image covers the div
            backgroundPosition: 'center', // Centers the image
        }}>
            <form className="auth-form" onSubmit={handleSignUp}>
                <button onClick={handleGoBack} className="back-button">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    >
                        <polyline points="15 18 9 12 15 6" />
                    </svg>
                </button>
                <h2>Đăng Ký</h2>
                <input
                    type="email"
                    value={email}
                    onChange={(e) => {
                        setEmail(e.target.value);
                        setIsEmailValid(true);
                    }}
                    placeholder="Email"
                    className={`auth-input ${!isEmailValid ? 'invalid' : ''}`}
                />
                {!isEmailValid && <p className="error-message">Email KHÔNG hợp lệ</p>}
                <input
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="Mật Khẩu"
                    className="auth-input"
                />
                <input
                    type="tel"
                    value={phone}
                    onChange={(e) => {
                        setPhone(e.target.value);
                        setIsPhoneValid(true);
                    }}
                    placeholder="Số Điện Thoại"
                    className={`auth-input ${!isPhoneValid ? 'invalid' : ''}`}
                />
                {!isPhoneValid && <p className="error-message">Số điện thoại KHÔNG hợp lệ</p>}
                <input
                    type="text"
                    value={nickname}
                    onChange={(e) => setNickname(e.target.value)}
                    placeholder="Tên hiển thị"
                    className="auth-input"
                />
                <button type="submit" className="auth-button">Đăng Ký</button>
            </form>
        </div>
    );
};

export default SignUp;